import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import imgPc from "assets/images/architecture/banner-pc.png";
import imgM from "assets/images/architecture/banner-m.png";
import IconImg from "assets/images/architecture/icon.png";
const ServicesBox = styled(Stack)`
  width: 100%;
  background-color: #272727;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 80px 0 75px 0;
  position: relative;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 30px 20px",
    },
  })};
`;

const Title1 = styled(Typography)`
  color: #FFF;
  font-family: Arboria-Bold;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: '36px',
    },
  })};
`;

const IconImgBox = styled('div')`
  width: 220px;
  height: 320px;
  position: absolute;
  right: -10%;
  top: -100px;
  background: url(${IconImg}) no-repeat center;
  background-size: contain;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: '99px',
      height: '144px',
      right: '0',
      top: '-50px',
    },
  })};
`;

const ImgCon = styled("img")`
  width: 110%;
  height: auto;
  transform: translateX(-5%);
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: '100%',
      transform: 'translateX(0)',
    },
  })};
`;

const Architecture = () => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ServicesBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <IconImgBox />
        <Title1>Architecture</Title1>
        <Box mt={downToMd ? 45 : 90}>
          <ImgCon src={downToMd ? imgM : imgPc}></ImgCon>
        </Box>
      </Containter>
    </ServicesBox>
  );
};

export default Architecture;
