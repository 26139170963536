import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import bg from "assets/images/privacy-bg.png";

const Containter = styled(Stack)`
  background: #fff;
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {},
  })};
`;

const Banner = styled(Stack)`
  width: 100%;
  height: 400px;
  background: linear-gradient(to right bottom, #000000, #002820);
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      height: "300px",
    },
  })};
`;

const PrCon = styled(Stack)`
  height: 100%;
  width: 960px;
  padding: 80px 0 160px 0;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 50px 20px",
    },
  })};
`;

const Head = styled(Typography)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
    },
  })};
`;

const BannerInner = styled(Stack)`
  width: 100%;
  height: 500px;
  background-image: url(${bg});
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
`;

const Title = styled(Typography)`
  color: #000;
  font-family: Arboria-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;

const InfoP = styled(Typography)`
  color: #000;
  font-family: Arboria-Book;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
`;

const PrivacyPolicy = () => {
  return (
    <Containter>
      <Banner>
        <BannerInner
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Head pt={70} variant="h1">
            Privacy Policy
          </Head>
        </BannerInner>
      </Banner>
      <Stack justifyContent="center" alignItems="center" direction="row">
        <PrCon>
          <InfoP>
            IntelliX Network ("IntelliX") respects the privacy of Personal Data and
            is dedicated to safeguarding it. This Data Protection and Privacy
            Policy (referred to as "Policy") outlines the measures and
            procedures implemented by IntelliX to protect Personal Data processed
            in accordance with relevant Data Protection Laws.
          </InfoP>
          <InfoP>
            IntelliX has chosen voluntarily to extend the protections and
            responsibilities outlined in this Policy to all Data Subjects.
            However, these protections and responsibilities may exceed the legal
            requirements in certain jurisdictions where Data Subjects reside.
            Data Protection Laws vary widely among jurisdictions, and while
            IntelliX aims to adhere to this Policy for all Data Subjects, the
            specific Data Protection Laws applicable to each Data Subject's
            jurisdiction ultimately govern the protections provided. This Policy
            does not confer any rights beyond those granted by the relevant Data
            Protection Laws.
          </InfoP>
          <InfoP>
            The Policy delineates how IntelliX collects and utilizes Personal
            Data, the situations in which IntelliX may share Personal Data, the
            rights applicable to Data Subjects, and the technical and physical
            safeguards IntelliX employs to ensure the security of Personal Data.
          </InfoP>
          <Title>DEFINITIONS</Title>
          <InfoP>
            Controller: Refers to a natural or legal entity, public authority,
            agency, or other entity that independently or jointly with others
            determines the purpose and means of Processing Personal Data, as
            defined in Data Protection Laws. In this context, the term
            "Controller" pertains to IntelliX, which may, in certain instances,
            act as a joint Controller with a third party.
          </InfoP>
          <InfoP>
            Data Protection Laws: Denotes applicable privacy laws, regulations,
            or guidelines issued by data protection authorities.
          </InfoP>
          <InfoP>
            Data Subject: Represents a natural person who can be directly or
            indirectly identified by reference to their Personal Data.
          </InfoP>
          <InfoP>
            Personal Data: Encompasses any information related to an identified
            or identifiable natural person (a Data Subject), as defined in Data
            Protection Laws. Personal Data also encompasses information
            pertaining to legal entities. However, Personal Data excludes data
            from which identity has been removed (anonymous data) and includes
            Special Category Data. Examples of Personal Data include but are not
            limited to: name, addresses, telephone numbers, email addresses,
            government-issued identification numbers, user passwords or PINs,
            user identification and account access credentials, financial
            account numbers, geolocation data.
          </InfoP>
          <InfoP>
            Process or Processing or Processed or Processes: Refers to any
            operation or series of operations performed on Personal Data,
            whether automated or not. These operations may include collecting,
            recording, using, organizing, storing, adapting, retrieving,
            disclosing, disseminating, erasing, or purging, among others.
          </InfoP>
          <InfoP>
            Processor: Denotes a natural or legal person, public authority,
            agency, or other entity that processes Personal Data on behalf of a
            Controller, as defined in Data Protection Laws. Processors typically
            handle the technical aspects of Processing and do not exercise
            professional judgment or significant decision-making regarding
            Personal Data. Processors may include third-party service providers,
            applications, or agencies utilized by IntelliX during business
            operations.
          </InfoP>
          <InfoP>
            Special Category Data: Refers to Personal Data that reveals racial
            or ethnic origin, criminal history, political opinions, religious or
            philosophical beliefs, sexual orientation, trade union membership,
            or health, genetic, or biometric data, or data concerning children
            or minors.
          </InfoP>
          <Title>A. GENERAL DATA PROTECTION INFORMATION</Title>
          <Title>DATA PROTECTION PRINCIPLES</Title>
          <InfoP>
            IntelliX is dedicated to processing data in compliance with its
            obligations under Data Protection Laws, particularly adhering to the
            following principles:
          </InfoP>
          <InfoP>(1) Fair, lawful, and transparent processing;</InfoP>
          <InfoP>
            (2) Collection for specified, explicit, and legitimate purposes,
            with no further processing incompatible with the specified purposes;
          </InfoP>
          <InfoP>
            (3) Limitation to what is adequate, relevant, and necessary
            concerning the purposes for processing Personal Data;
          </InfoP>
          <InfoP>
            (4) Ensuring accuracy and keeping Personal Data up to date;
          </InfoP>
          <InfoP>
            (5) Retaining Personal Data only for as long as necessary or legally
            required;
          </InfoP>
          <InfoP>
            (6) Processing Personal Data with appropriate security measures to
            prevent unauthorized or unlawful processing, accidental loss,
            destruction, or damage;
          </InfoP>
          <InfoP>
            (7) Processing Personal Data while respecting the rights of the Data
            Subject; and
          </InfoP>
          <InfoP>
            (8) Ensuring an adequate level of data protection when transferring
            Personal Data to a country outside of the European Economic Area.
          </InfoP>
          <Title>LEGAL BASIS FOR PROCESSING PERSONAL DATA</Title>
          <InfoP>
            IntelliX will utilize Personal Data only when permitted by Data
            Protection Laws. Personal Data shall be processed in a manner that
            is adequate, relevant, and not excessive concerning the intended
            business purpose(s) of such processing. Processing of Personal Data
            requires a legal basis. Personal Data may be processed only if at
            least one of the following legal bases applies:
          </InfoP>
          <InfoP>
            (1) Consent: Data Subject providing consent to the Processing of
            their Personal Data for specific purpose(s);
          </InfoP>
          <InfoP>
            (2) Contractual necessity: Processing necessary for the performance
            of a contract to which the Data Subject is a party or entering into
            a contract, such as due diligence checks, compliance with applicable
            legislation, regulatory requests, surveillance activities, audit
            checks, and fraud prevention;
          </InfoP>
          <InfoP>
            (3) Compliance with legal obligations: Processing necessary for
            compliance with a legal obligation to which IntelliX is subject;
          </InfoP>
          <InfoP>
            (4) Vital interests: Processing necessary to protect the vital
            interests of the Data Subject or another natural person;
          </InfoP>
          <InfoP>
            (5) Public interests: Processing permitted if necessary for the
            performance of a task carried out in the public interest, such as
            taxation, crime reporting, public health, and product quality and
            safety; and
          </InfoP>
          <InfoP>
            (6) Legitimate interests: Processing necessary for the legitimate
            interests pursued by IntelliX or a third party, except where
            overridden by the interests or fundamental rights and freedoms of
            the Data Subject requiring protection of Personal Data, particularly
            where the Data Subject is a child.
          </InfoP>
          <Title>RIGHTS OF DATA SUBJECTS</Title>
          <InfoP>
            When applicable, IntelliX will respect the rights of Data Subjects as
            described below. In accordance with Data Protection Laws and under
            certain circumstances, a Data Subject may have the right to:
          </InfoP>
          <InfoP>
            (1) Request access to their Personal Data held by IntelliX to verify
            its accuracy and lawful processing;
          </InfoP>
          <InfoP>
            (2) Request correction of their Personal Data held by IntelliX,
            enabling individuals to correct any incomplete or inaccurate
            information, subject to verification;
          </InfoP>
          <InfoP>
            (3) Request erasure of their Personal Data, allowing individuals to
            ask IntelliX to delete or remove Personal Data where no legitimate
            purpose exists for its processing. IntelliX may not always comply with
            erasure requests for legal reasons, which will be communicated to
            individuals if applicable;
          </InfoP>
          <InfoP>
            (4) Object to the processing of their Personal Data by IntelliX, where
            IntelliX relies on legitimate interests and the processing impacts
            their fundamental rights and freedoms;
          </InfoP>
          <InfoP>
            (5) Request restriction of processing of their Personal Data,
            enabling individuals to suspend processing in certain scenarios,
            such as verifying data accuracy or objecting to processing pending
            verification of overriding legitimate grounds;
          </InfoP>
          <InfoP>
            (6) Request the transfer of their Personal Data, with IntelliX
            providing the data in a structured, commonly used, machine-readable
            format to the individual or a third party;
          </InfoP>
          <InfoP>
            (7) Withdraw consent at any time if IntelliX relies on consent for
            processing, without affecting the lawfulness of prior processing;
            and
          </InfoP>
          <InfoP>
            (8) Be notified of data breaches involving their Personal Data, with
            IntelliX accepting written requests through appropriate channels to
            exercise rights under Data Protection Laws and verifying the
            requester's identity.
          </InfoP>
          <Title>IntelliX DATA PROTECTION OBLIGATIONS</Title>
          <InfoP>
            IntelliX and its personnel have implemented suitable technical and
            organizational measures to protect Data Subjects' rights and ensure
            lawful, fair, and transparent processing of Personal Data. IntelliX's
            data protection obligations include:
          </InfoP>
          <InfoP>
            (1) Consent: Obtaining affirmative consent from Data Subjects before
            processing their Personal Data, ensuring clear and plain language
            and the option to withdraw consent at any time;
          </InfoP>
          <InfoP>
            (2) Purpose Limitation: Restricting the processing of Personal Data
            to intended business purposes;
          </InfoP>
          <InfoP>
            (3) Notification: Providing clear notification to Data Subjects at
            the outset of processing, including Controller information,
            processing purposes, types of Personal Data, access details,
            retention periods, Data Subject rights, and breach protections;
          </InfoP>
          <InfoP>
            (4) Access: Granting Data Subjects access to their Personal Data and
            information on previous processing upon request;
          </InfoP>
          <InfoP>
            (5) Correction: Correcting errors or omissions in Data Subjects'
            Personal Data upon request and informing relevant third parties of
            corrections;
          </InfoP>
          <InfoP>
            (6) Erasure: Deleting Data Subjects' Personal Data upon request,
            subject to certain conditions, and notifying third parties of
            erasure;
          </InfoP>
          <InfoP>
            (7) Accuracy: Ensuring the accuracy and completeness of Personal
            Data processed by IntelliX;
          </InfoP>
          <InfoP>
            (8) Protection: Safeguarding Personal Data against unauthorized
            processing;
          </InfoP>
          <InfoP>
            (9) Retention: Retaining Personal Data only as long as necessary for
            the intended purpose and ceasing retention when no longer necessary;
            and
          </InfoP>
          <InfoP>
            (10) Breach Notification: Notifying authorities and Data Subjects of
            data breaches within specified timeframes depending on the risk
            level.
          </InfoP>
          <Title>SHARING OF PERSONAL DATA</Title>
          <InfoP>
            IntelliX has implemented appropriate measures to protect Data
            Subjects' privacy and fundamental rights when sharing Personal Data,
            supported by relevant documentation and safeguards.
          </InfoP>
          <InfoP>
            IntelliX may disclose Personal Data to third parties for processing
            (maintenance, storage, use) on behalf of IntelliX. IntelliX requires all
            such processors to implement appropriate security measures to
            safeguard Personal Data in line with IntelliX's policies. Processors
            are prohibited from processing Personal Data for their own purposes
            and are only authorized to process it for specified purposes and in
            accordance with IntelliX's instructions.
          </InfoP>
          <InfoP>
            IntelliX may share Personal Data with third parties for processing on
            their own behalf. These third parties will be considered joint
            controllers of the Personal Data. While joint controllers have
            shared discretion over the purposes of processing, they agree to
            process such shared Personal Data in compliance with Data Protection
            Laws.
          </InfoP>
          <InfoP>
            IntelliX may engage with processors or joint controllers, such as
            third-party service providers, applications, or agencies, to process
            Personal Data. Prior to allowing the processing of Personal Data by
            a processor or joint controller, IntelliX will collaborate with these
            parties to ensure that adequate documentation and security measures
            are in place to process Personal Data in accordance with Data
            Protection Laws. Processors or joint controllers must limit
            processing to the intended business purpose.
          </InfoP>
          <InfoP>
            IntelliX will not transfer Personal Data to a country or territory
            outside the jurisdiction in which it was processed, except as
            required by Data Protection Laws. IntelliX may transfer or grant
            access to Personal Data across jurisdictions and entities in line
            with its Data Protection Agreements and Intercompany Agreements,
            which comply with Data Protection Laws.
          </InfoP>
          <InfoP>
            Various third parties are explicitly mentioned in this Policy (e.g.,
            in the "Tracking Tools" section). Personal Data is shared or
            accessed to provide and maintain website functionalities.
          </InfoP>
          <Title>TRANSFER OF PERSONAL DATA ABROAD</Title>
          <InfoP>
            IntelliX may transfer your Personal Data to third parties located
            abroad for the purposes of data processing outlined in this Policy.
            These third parties are obligated to protect individuals' privacy to
            the same extent as IntelliX. If the level of data protection in a
            country does not meet Swiss or European standards, IntelliX ensures,
            through contractual agreements, that Personal Data protection
            remains equivalent to that in Switzerland or the EU at all times.
            This involves adopting EU standard contractual clauses and
            implementing additional technical and organizational measures, if
            necessary.
          </InfoP>
          <InfoP>
            Certain third parties mentioned in this Policy are based in the USA
            (refer to the "Links to Our Social Media Presences" or "Tracking
            Tools" sections). Further details regarding data transfers to the
            USA are provided below in the "Note on Data Transfers to the USA".
          </InfoP>
          <Title>NOTE ON DATA TRANSFERS TO THE USA</Title>
          <InfoP>
            Some of the third parties mentioned in this Policy are located in
            the USA. For the sake of completeness, IntelliX would like to inform
            Data Subjects who are residents or domiciled in Switzerland or the
            EU that there are surveillance measures in place in the USA by US
            authorities. These measures generally permit the storage of all
            Personal Data of individuals whose data has been transferred from
            Switzerland or the EU to the USA. This is done without
            differentiation, limitation, or exception based on the objective
            pursued, and without any objective criterion to limit the access of
            US authorities to the data and its subsequent use to specific,
            strictly limited purposes that could justify the intrusion
            associated with both the access to and the use of this data.
            Furthermore, IntelliX would like to highlight that Data Subjects from
            Switzerland or the EU do not have legal recourse in the USA to
            access, correct, or delete their data, nor is there effective
            judicial protection against the general access rights of US
            authorities.
          </InfoP>
          <InfoP>
            IntelliX wishes to alert Data Subjects residing in Switzerland or a
            member state of the EU that, from the perspective of the European
            Union and Switzerland, the USA does not provide an adequate level of
            data protection, partly due to the issues mentioned in this section.
            When IntelliX indicates in this Policy that data recipients (such as
            Google) are located in the USA, IntelliX will ensure through
            contractual agreements with these companies, as well as any
            additional appropriate guarantees required, that your Personal Data
            is adequately protected by our partners.
          </InfoP>
          <Title>SECURITY MEASURES TO PROTECT PERSONAL DATA</Title>
          <InfoP>
            IntelliX has incorporated elements of privacy by design along with
            technical and physical safeguards to safeguard the security of
            Personal Data from unauthorized or unlawful processing. IntelliX
            utilizes various systems and applications to maintain the security
            of Personal Data, including anonymization and encryption, ensuring
            ongoing confidentiality, integrity, availability, and resilience of
            processing, timely restoration of data availability and access in
            case of incidents, and regular testing and evaluation of security
            measures, at least annually.
          </InfoP>
          <InfoP>
            When assessing the appropriate level of security and the risks posed
            to the rights and freedoms of Data Subjects, IntelliX evaluates the
            risks associated with the Processing of Personal Data. These risks
            may include, but are not limited to, accidental, unlawful, or
            unauthorized destruction, loss, disclosure, alteration, or access to
            Personal Data processed by or on behalf of IntelliX, or other factors
            that may impact Data Subject rights and freedoms. IntelliX will take
            reasonable measures to mitigate any risks associated with the
            Processing of Personal Data through technological and/or
            organizational controls, including restricted access to Personal
            Data using access controls and password protections.
          </InfoP>
          <Title>THIRD PARTY WEBSITES AND SOCIAL MEDIA</Title>
          <InfoP>
            This website may contain content and links to third-party websites
            that are not owned, operated, or controlled by IntelliX. IntelliX does
            not assume responsibility for the privacy practices or content
            displayed on such third-party websites. When interacting with
            IntelliX’s content on or through a third-party social networking
            website, plug-in, or application, IntelliX may Process Personal Data
            associated with your social media account.
          </InfoP>
          <Title>
            B. SCOPE AND PURPOSE OF THE COLLECTION, PROCESSING, AND USE OF
            PERSONAL DATA WHEN VISITING OUR WEBSITES
          </Title>
          <InfoP>
            When visiting our websites, our servers temporarily log each access
            in a log file. The following data may be collected automatically and
            stored by IntelliX until deletion, as required by applicable laws: the
            IP address of the requesting computer; the name of your internet
            service provider; the date and time of access; the name and URL of
            the retrieved file; the page and address of the website from which
            you were redirected to our website, and if applicable, the search
            term used; the country from which the website is accessed; the
            operating system and browser information (provider, version, and
            language); and the transmission protocol used (e.g., HTTP/1.1). The
            collection and processing of this data aim to facilitate website
            usage (establishing connections), ensure system security and
            stability, optimize our internet offerings, and for internal
            statistical purposes. Our legitimate interest in data processing is
            based on the purposes outlined above.
          </InfoP>
          <InfoP>
            Only in the event of an attack on the network infrastructure or
            suspicion of other unauthorized or abusive website use, the IP
            address will be evaluated for clarification and defense purposes. If
            necessary, it may be used in the context of criminal proceedings to
            identify and take civil or criminal action against the users
            involved. Our legitimate interest in data processing is based on the
            purposes outlined above.
          </InfoP>
          <InfoP>
            Additionally, IntelliX utilizes cookies and other cookie-based
            applications when you visit the website. For more details, please
            refer to the "Cookies" and "Tracking Tools" sections.
          </InfoP>
          <Title>CONTACTING US BY EMAIL</Title>
          <InfoP>
            On the website, you have the option to contact IntelliX or one of its
            experts via email, including for support inquiries. You are
            responsible for the content you transmit to IntelliX and are advised
            against sending confidential data. Personal Data is only collected
            if voluntarily provided to IntelliX. Thus, you are accountable for the
            data transmitted. IntelliX may request additional information to
            adequately address your questions or provide requested services.
            IntelliX has a legitimate interest in processing your email inquiries,
            and you can object to this data processing at any time (refer to the
            "Contact" section).
          </InfoP>
          <Title>APPLYING AS A DATA PROVIDER</Title>
          <InfoP>
            When applying as a IntelliX Data Provider, you may be required to
            provide the following information in the online application form:
            Email, Company, First Name, Last Name, Street, Zip, City, and
            Country. The legal basis for processing your Personal Data includes
            processing for pre-contractual purposes, performance of a contract,
            and IntelliX's legitimate interest. If IntelliX relies on legitimate
            interest for data processing, you can object to it at any time
            (refer to the "Contact" section).
          </InfoP>
          <Title>COOKIES, SCRIPTS, AND RELATED TECHNOLOGIES</Title>
          <InfoP>
            When visiting this website, IntelliX and its third-party service
            providers collect and record Personal Data you may have provided,
            including your IP address. We utilize technologies like cookies,
            tracking scripts, pixels, and tagging technologies to track your
            website navigation, understand your preferences, and enhance your
            browsing experience. While most internet browsers accept cookies by
            default, you can configure your browser to reject cookies or prompt
            you when receiving a new cookie. Please note that disabling cookies
            may limit your access to website features.
          </InfoP>
          <Title>TRACKING TOOLS</Title>
          <InfoP>
            Google Analytics: We utilize Google Analytics, a web analytics
            service provided by Google Ireland Limited, Google Inc., or Google
            LLC. Google Analytics analyzes website usage using cookies,
            generating information such as the path visitors take on the
            website, time spent on the website and its pages, exit page,
            location of website access, device details, visitor type, browser
            details, operating system used, referral URL, accessing computer's
            host name (IP address), or server request time. This information is
            transmitted to Google servers in the USA and stored there. The IP
            address is anonymized before transmission within the EU/EEA or
            Switzerland. Google may transfer this data to third parties as
            required by law or for data processing on Google's behalf. Google
            assures that IP addresses will not be associated with other user
            data. Processing data for these purposes relies on your consent,
            given through the cookie banner. You can revoke this consent at any
            time (refer to the "Contact" section). You can prevent Google from
            collecting and processing your data by installing the browser plugin
            available at http://tools.google.com/dlpage/gaoptout?hl=en. An
            opt-out cookie will be stored on your device. Click the link again
            if you delete all cookies.
          </InfoP>
          <InfoP>
            Google Tag Manager: We use Google Tag Manager to manage cookies,
            pixels, and other tools. Google Tag Manager itself does not collect
            Personal Data but triggers other tags that may collect data. If you
            opt out, it applies to all tags managed with Google Tag Manager.
          </InfoP>
          <InfoP>
            This processing is conducted based on our legitimate interest. You
            have the right to object to this data processing at any time (refer
            to the "Contact" section).
          </InfoP>
          <Title>LINKS TO OUR SOCIAL MEDIA PRESENCES</Title>
          <InfoP>
            We have included links to our social media accounts on the following
            networks: Twitter Inc., Discord Inc., YouTube LLC, Telegram.
            Clicking these links redirects you to our profiles on these
            networks, where you may need to log into your account to access
            network functions. When accessing our social media links, your
            browser establishes a direct connection with the respective
            network's server, which receives information about your website
            visit and IP address. If you are logged into your account, our
            website content may be linked to your profile. To prevent
            association, it is recommended to log out before clicking the links.
            By clicking these links, you consent to subsequent data processing.
          </InfoP>
          <Title>REVIEW AND UPDATES TO POLIC</Title>
          <InfoP>
            IntelliX periodically reviews and updates this Policy to align with
            changes in privacy practices or security measures. Any identified
            deficiencies are promptly addressed. Please check this Policy
            periodically for the latest updates. Your continued use of IntelliX's
            website after updates indicates your acknowledgment and
            understanding of the Policy.
          </InfoP>
          <Title>CONTACT</Title>
          <InfoP>
            For inquiries or comments regarding this Policy or your Personal
            Data, please contact IntelliX via email at 0x@IntelliX.network.
          </InfoP>
        </PrCon>
      </Stack>
    </Containter>
  );
};

export default PrivacyPolicy;
