import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import Icon1Img from "assets/images/innovations/Icon1.png";
import Icon2Img from "assets/images/innovations/Icon2.png";
import Icon3Img from "assets/images/innovations/Icon3.png";
import Icon4Img from "assets/images/innovations/Icon4.png";
const ServicesBox = styled(Stack)`
  width: 100%;
  background-color: #F6F6F6;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 80px 0 75px 0;
  position: relative;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 30px 20px",
    },
  })};
`;

const Title1 = styled(Typography)`
  color: #272727;
  font-family: Arboria-Bold;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: '36px',
    },
  })};
`;

const MainBox = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 80px;
  column-gap: 60px;
  padding: 100px 0 150px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: '1fr',
      columnGap: '0',
      padding: '50px 0 80px',
    },
  })};
`;

const ItemIconBox = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  img{
    width: 200px;
    height: 200px;
    vertical-align: middle;
  }
  .title{
    margin: 45px 0 23px;
    color: #272727;
    text-align: center;
    font-family: Arboria-Medium;
    font-size: 36px;
    line-height: 28px;
  }
  .desc{
    color: #272727;
    text-align: center;
    font-family: Arboria-Book;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      'img': {
        width: '150px',
        height: '150px',
      },
      '.title': {
        margin: '40px 0 25px',
        fontSize: '20px',
        lineHeight: '25px',
      },
      '.desc': {
        fontSize: '14px',
        lineHeight: '20px',
      }
    },
  })};
`;

const Innovations = () => {
  const theme = useTheme();
  return (
    <ServicesBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Title1>Innovations</Title1>
        <MainBox>
          <ItemIconBox>
            <img src={Icon1Img} alt="icon" />
            <div className="title">Trust in Cryptoeconomic Security</div>
            <div className="desc">Traditionally, oracles rely on bootstrapped branded stake pools, introducing trust assumptions and attack vectors to consumer applications.</div>
          </ItemIconBox>
          <ItemIconBox>
            <img src={Icon2Img} alt="icon" />
            <div className="title">Globally Distributed Operations</div>
            <div className="desc">Unlike the open nature of the blockchain ecosystem, traditional oracle nodes are restricted to a selected group for registration and operation.</div>
          </ItemIconBox>
          <ItemIconBox>
            <img src={Icon3Img} alt="icon" />
            <div className="title">AI-Driven & Programmable</div>
            <div className="desc">Traditional oracles act as intermediaries, controlling costs, supply, and types of data. They also lack the ability to handle and learn from unstructured data.</div>
          </ItemIconBox>
          <ItemIconBox>
            <img src={Icon4Img} alt="icon" />
            <div className="title">Permissionless Integration</div>
            <div className="desc">Instead of hindering industry growth with infrastructure limits, applications can access necessary data anywhere without losing efficiency.</div>
          </ItemIconBox>
        </MainBox>
      </Containter>
    </ServicesBox>
  );
};

export default Innovations;
