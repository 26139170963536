// CodeBlock.tsx
import React from "react";
import { styled } from "@mui/material/styles";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism"; // 可替换主题

const SyntaxHighlighterBox = styled(SyntaxHighlighter)`
  .linenumber {
    min-width: 20px !important;
    font-family: Arboria-Book;
    font-size: 16px;
  }
  .token{
    /* color: #00D1AB !important; */
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: '12px !important',
    },
  })};
`;

const customStyleOverrides = {
  'code[class*="language-"]': {
    backgroundColor: "#F0F0F0", // 自定义背景色
    borderRadius: "8px",
    padding: "16px",
    fontSize: "16px",
    border: "1px solid #999",
    fontFamily: "Arboria-Book",
    maxHeight: "200px",
    color: 'red !important',
    fontStyle: "italic",
    maxWidth: '800px'
  },
};

const CodeBlock: React.FC<{ code: string }> = ({ code }) => {
  return (
    <SyntaxHighlighterBox
      language="python"
      style={tomorrow} // 使用自定义的主题样式
      customStyle={customStyleOverrides['code[class*="language-"]']}
      showLineNumbers={true} // 显示行号
    >
      {code}
    </SyntaxHighlighterBox>
  );
};

export default CodeBlock;
