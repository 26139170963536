import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const LogoWhite: React.FC<IconProps> = ({ width = "124px", height = "34px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg width="124" height="34" viewBox="0 0 124 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5587 0H8.44134C3.77932 0 0 3.77932 0 8.44134V25.5587C0 30.2207 3.77932 34 8.44134 34H25.5587C30.2207 34 34 30.2207 34 25.5587V8.44134C34 3.77932 30.2207 0 25.5587 0Z" fill="white" />
        <path d="M40.4944 9.13892H45.2573V26.4909H40.4944V9.13892Z" fill="white" />
        <path d="M48.4473 14.5361H52.2842V16.9588C52.7791 16.1444 53.4249 15.4319 54.1869 14.8596C54.9632 14.345 55.8818 14.0876 56.8125 14.1239H57.3326H57.4848C57.5332 14.1305 57.5822 14.1305 57.6306 14.1239C58.235 14.1935 58.8193 14.3833 59.3493 14.682C59.8893 14.9819 60.3401 15.4195 60.6558 15.9504C60.9072 16.3967 61.0688 16.888 61.1315 17.3964C61.1949 17.9038 61.2329 18.4175 61.252 18.9439V26.4909H57.0535V20.0981C57.0535 19.9523 57.0535 19.8064 57.0535 19.6606C57.0535 19.5147 57.0535 19.3624 57.0535 19.1975C57.0014 18.8706 56.9099 18.5511 56.7808 18.2462C56.6574 17.9435 56.4334 17.6927 56.1466 17.5359C56.0596 17.4939 55.9681 17.4621 55.8739 17.4408C55.7657 17.4157 55.6551 17.4029 55.5441 17.4027C55.4725 17.3935 55.4 17.3935 55.3284 17.4027H54.9606C54.8974 17.3946 54.8335 17.3946 54.7703 17.4027C54.6922 17.3966 54.6138 17.3966 54.5357 17.4027C54.4667 17.4069 54.3985 17.4197 54.3327 17.4408C54.0423 17.5209 53.7739 17.6658 53.5476 17.8646C53.3213 18.0635 53.143 18.311 53.0263 18.5887C52.8066 19.1348 52.678 19.7133 52.6457 20.3011C52.6457 20.504 52.614 20.6943 52.614 20.8846V26.4846H48.479L48.4473 14.5361Z" fill="white" />
        <path d="M63.8398 17.4787H62.064V14.536H63.8398V10.9401H67.9811V14.536H69.8013V17.4787H67.9811V26.4908H63.8398V17.4787Z" fill="white" />
        <path d="M74.5641 21.3157C74.5861 22.0442 74.8221 22.75 75.2427 23.3452C75.4775 23.6271 75.7742 23.851 76.1097 23.9995C76.4452 24.148 76.8105 24.217 77.177 24.2013H77.2595H77.2975C77.8154 24.2085 78.3271 24.0888 78.7879 23.8526C79.2492 23.5996 79.6295 23.2214 79.8851 22.7617L83.0562 24.0301C82.6001 24.9942 81.8121 25.7619 80.8364 26.1928C79.841 26.651 78.7611 26.8973 77.6654 26.9158H77.3419C76.3218 26.8899 75.3154 26.6748 74.3738 26.2816C73.4747 25.9468 72.6773 25.3853 72.059 24.6517C71.6169 24.1352 71.2614 23.5504 71.0062 22.9202C70.7472 22.2928 70.5971 21.6257 70.5622 20.9478V20.7322C70.5567 20.6689 70.5567 20.6052 70.5622 20.5419C70.5693 20.4692 70.582 20.3971 70.6003 20.3263C70.6003 20.2756 70.6003 20.2059 70.6003 20.1361C70.6062 20.0728 70.6062 20.0091 70.6003 19.9458C70.6647 19.1225 70.8965 18.321 71.2815 17.5904C71.6664 16.8597 72.1964 16.2153 72.839 15.6966C74.0724 14.6807 75.6236 14.1307 77.2214 14.1428C77.563 14.1437 77.9041 14.167 78.2425 14.2125C78.5867 14.2594 78.9277 14.3272 79.2636 14.4155C80.9696 14.9102 82.1112 15.7917 82.6947 17.0728C83.2904 18.4155 83.6055 19.8658 83.6206 21.3347L74.5641 21.3157ZM75.4774 17.3138C75.0804 17.7803 74.8116 18.342 74.6973 18.9438H79.5173C79.4473 18.346 79.1846 17.7872 78.7689 17.3519C78.554 17.1361 78.2977 16.9659 78.0155 16.8515C77.7332 16.7371 77.4308 16.6808 77.1263 16.686C76.8222 16.6634 76.5169 16.7079 76.232 16.8164C75.947 16.9249 75.6894 17.0947 75.4774 17.3138Z" fill="white" />
        <path d="M85.5676 8.13696H89.7851V26.491H85.5676V8.13696Z" fill="white" />
        <path d="M92.3792 8.13696H96.603V26.491H92.3792V8.13696Z" fill="white" />
        <path d="M99.8246 9.158C100.224 8.69787 100.785 8.40936 101.391 8.35254H101.467C101.774 8.3573 102.077 8.42768 102.355 8.55894C102.633 8.69019 102.879 8.87933 103.078 9.11364C103.528 9.57259 103.781 10.1895 103.782 10.8323C103.799 11.1339 103.747 11.4354 103.63 11.7136C103.512 11.9918 103.332 12.2393 103.103 12.4369C102.679 12.8082 102.144 13.0309 101.581 13.0711H101.309C100.744 13.0309 100.208 12.8083 99.7802 12.4369C99.5529 12.2383 99.3737 11.9907 99.2562 11.7127C99.1386 11.4348 99.0858 11.1337 99.1016 10.8323C99.1039 10.5191 99.1691 10.2094 99.2933 9.92181C99.4175 9.6342 99.5982 9.37448 99.8246 9.158ZM99.349 14.5361H103.592V26.4909H99.3553L99.349 14.5361Z" fill="white" />
        <path d="M114.627 13.8638C114.714 14.0914 114.786 14.3244 114.843 14.5614C114.843 14.5044 114.881 14.46 114.894 14.4092L114.951 14.2887C115.02 14.1048 115.103 13.9209 115.185 13.7306C115.266 13.5377 115.362 13.3512 115.471 13.1725L117.538 9.13892H123.151L117.373 17.4788L123.773 26.4909H118.439L115.471 21.3158C115.376 21.1728 115.293 21.0221 115.223 20.8655C115.151 20.7132 115.088 20.5547 115.033 20.3898L114.989 20.2947C114.979 20.2618 114.967 20.2299 114.951 20.1996L114.894 20.079C114.894 20.0283 114.862 19.9839 114.843 19.9268L114.786 20.1551C114.769 20.2367 114.741 20.3156 114.703 20.3898C114.653 20.5533 114.589 20.7124 114.513 20.8655C114.443 21.0208 114.362 21.1713 114.272 21.3158L111.329 26.4909H105.989L112.388 17.3963L106.592 9.15161H112.198L114.272 13.1852C114.407 13.4022 114.526 13.6291 114.627 13.8638Z" fill="white" />
        <path d="M24.8229 17.4979C25.0983 17.406 25.3377 17.2297 25.5074 16.994C25.677 16.7584 25.7681 16.4753 25.7678 16.185V12.8301C25.767 12.6106 25.7141 12.3944 25.6136 12.1993C25.5131 12.0042 25.3678 11.8357 25.1895 11.7076C25.0113 11.5795 24.8053 11.4955 24.5883 11.4624C24.3713 11.4293 24.1496 11.4481 23.9413 11.5172L17.3582 13.756H17.2567L9.15786 16.4831C8.88246 16.575 8.64297 16.7513 8.47336 16.9869C8.30374 17.2225 8.21261 17.5056 8.21289 17.7959V21.1509C8.21274 21.3709 8.26485 21.5878 8.36495 21.7837C8.46504 21.9797 8.61025 22.149 8.78861 22.2778C8.96697 22.4066 9.17338 22.4913 9.39085 22.5247C9.60831 22.5581 9.8306 22.5394 10.0394 22.4701L16.7494 20.2123C16.9206 20.2123 17.2314 20.3835 17.2377 21.8041C17.2347 21.8421 17.2347 21.8803 17.2377 21.9183V25.3176C17.2375 25.6192 17.3359 25.9126 17.518 26.1531C17.7001 26.3935 17.9558 26.5679 18.2461 26.6495L24.0237 28.2921C24.2298 28.3511 24.4467 28.3614 24.6573 28.3222C24.868 28.283 25.0666 28.1953 25.2376 28.0662C25.4086 27.937 25.5472 27.7698 25.6425 27.5779C25.7379 27.386 25.7873 27.1745 25.7869 26.9602V23.5609C25.787 23.2593 25.6886 22.9659 25.5065 22.7255C25.3245 22.485 25.0688 22.3107 24.7785 22.229L19.8506 20.8275C19.4257 20.7006 18.0622 20.2376 18.5822 19.6098L24.8229 17.4979Z" fill="#081909" />
        <path d="M16.7557 12.145C16.7557 12.145 16.7557 12.1006 16.7557 12.0753V8.68227C16.7559 8.38067 16.6575 8.0873 16.4754 7.84685C16.2934 7.6064 16.0377 7.43207 15.7473 7.35045L9.97601 5.70786C9.77001 5.64889 9.55311 5.63853 9.34245 5.67775C9.13178 5.71697 8.93312 5.80461 8.76213 5.93377C8.59115 6.06293 8.45253 6.23013 8.35721 6.42205C8.2619 6.61396 8.2125 6.82541 8.21291 7.03969V10.439C8.21128 10.7401 8.3084 11.0334 8.4894 11.2739C8.67039 11.5145 8.92526 11.689 9.21496 11.7709L14.2886 13.2105C14.815 13.3754 15.9312 13.8003 15.4746 14.3711L17.2758 13.7369C17.0792 13.7559 16.7557 13.6227 16.7557 12.145Z" fill="#081909" />
      </svg>
    </SvgIcon>
  );
};

export default LogoWhite;
