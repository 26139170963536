import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import BannerBg from "assets/images/banner.svg";
import BannerBgM from "assets/images/banner-m.png";
import BannerImgPc from "assets/images/banner-logo-pc.png";
import BannerImgM from "assets/images/banner-logo-m.png";

const BanBox = styled(Stack)`
  width: 100%;
  height: 100vh;
  background: url(${BannerBg}) center no-repeat;
  background-size: cover;
  position: relative;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: '100%',
      background: `url(${BannerBgM}) center no-repeat`,
    },
  })};
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })};
  @media screen and (min-width: 2000px) {
    width: 80%;
  }
`;

const PcBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  })};
`;

const MBox = styled(Stack)`
  padding: 20px;
  ${({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  })};
`;

const TextBox = styled(Box)`
  width: 750px;
  @media screen and (min-width: 2000px) {
    width: 35vw;
  }
`;

const ImgBox = styled(Stack)`
  width: 400px;
  @media screen and (min-width: 2000px) {
    width: 20vw;
  }
`;

const ImgCon = styled("img")`
  width: 100%;
  height: auto;
`;

const HeadPc = styled(Typography)`
  margin: 0;
  color: #fff;
  font-size: 64px;
  font-family: Arboria-Medium;
  font-weight: 300;
  line-height: 1.167;
  @media screen and (min-width: 2000px) {
    font-size: 3.5vw;
  }
`;

const DescPc = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  @media screen and (min-width: 2000px) {
    font-size: 1vw;
  }
`;

const TitleDescPc = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  @media screen and (min-width: 2000px) {
    font-size: 1.2vw;
  }
`;

const DescM = styled(Typography)`
  color: #fff;
  font-family: Arboria-Book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TitleDescM = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Banner = () => {
  const theme = useTheme();
  const upToLg = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <BanBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack
          height={"100%"}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <PcBox
            justifyContent={"space-between"}
            alignItems={"center"}
            direction="row"
            height={"100%"}
            width={"100%"}
          >
            <TextBox>
              <Box>
                <HeadPc>The Next-Gen Oracle for Decentralized Applications</HeadPc>
              </Box>
              <Box>
                <TitleDescPc mt={32}>Fully Programmable, AI-Powered Oracles for All Your Needs</TitleDescPc>
                <DescPc mt={38}>IntelliX is a powerful, programmable oracle service designed to bridge the gap between off-chain and on-chain data. From standard price feeds to advanced AI/LLM-driven data processing, IntelliX ensures accurate, real-time data for smart contract execution.</DescPc>
              </Box>
            </TextBox>
            <ImgBox justifyContent="center" alignItems="center" direction="row">
              <ImgCon src={BannerImgPc} />
            </ImgBox>
          </PcBox>
          <MBox
            justifyContent="space-between"
            alignItems="center"
            direction="column"
            height={"100%"}
          >
            <Box mt={120}>
              <Typography fontSize={48} lineHeight={'46px'} variant="h1">
                The Next-Gen Oracle for Decentralized Applications
              </Typography>
            </Box>
            <Stack
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
              mt={20}
              width={"100%"}
              pr={40}
            >
              <ImgCon
                style={{ width: "60%", height: "auto" }}
                src={BannerImgM}
              />
            </Stack>
            <Box mb={120}>
              <TitleDescM mt={25}>Fully Programmable, AI-Powered Oracles for All Your Needs</TitleDescM>
              <DescM mt={30}>IntelliX is a powerful, programmable oracle service designed to bridge the gap between off-chain and on-chain data. From standard price feeds to advanced AI/LLM-driven data processing, IntelliX ensures accurate, real-time data for smart contract execution.</DescM>
            </Box>
          </MBox>
        </Stack>
      </Containter>
    </BanBox>
  );
};

export default Banner;
