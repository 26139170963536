import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import Icon1Img from "assets/images/features/icon1.png";
import Icon2Img from "assets/images/features/icon2.png";
import Icon3Img from "assets/images/features/icon3.png";
import BannerImg from "assets/images/features/banner-img.png";
import BannerMImg from "assets/images/features/banner-img-m.png";
import CodeBlock from "components/CodeBlock";
const ServicesBox = styled(Stack)`
  width: 100%;
  background-color: #F6F6F6;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 80px 0 75px 0;
  position: relative;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 30px 20px",
    },
  })};
`;

const Title1 = styled(Typography)`
  color: #272727;
  font-family: Arboria-Bold;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: '36px',
    },
  })};
`;

const MainBox = styled("div")`
  padding: 80px 0 35px;
  display: flex;
  flex-direction: column;
  gap: 90px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      padding: '60px 0 50px',
    },
  })};
`;

const ItemBox = styled("div")<{isreverse?: boolean}>`
  display: flex;
  justify-content: space-between;
  gap: 80px;
  .icon-img{
    width: 362px;
    height: 362px;
    vertical-align: middle;
  }
  .item-main-box{
    flex: 1;
    width: 100%;
    .title{
      color: #272727;
      font-family: Arboria-Medium;
      font-size: 36px;
      line-height: 28px;
    }
    .desc{
      color: #272727;
      font-family: Arboria-Book;
      font-size: 16px;
      line-height: 28px;
      margin: 26px 0;
    }
    .desc2{
      width: 68%;
    }
    img{
      width: 90%;
      vertical-align: middle;
    }
  }
  ${({ theme, isreverse }) => ({
    [theme.breakpoints.down("md")]: {
      gap: '50px',
      flexDirection: isreverse ? 'column-reverse' : 'column',
      alignItems: 'center',
      '.icon-img': {
        width: '200px',
        height: '200px',
      },
      '.item-main-box': {
        '.title': {
          textAlign: 'center',
          fontSize: '20px',
          lineHeight: '25px',
        },
        '.desc': {
          textAlign: 'center',
          fontSize: '14px',
          lineHeight: '20px',
          margin: '25px 0 35px',
        },
        '.desc2': {
          width: '100%',
        },
        'img': {
          width: '80%',
          margin: '0 auto',
          display: 'block',
        }
      }
    },
  })};
`;

const Features = () => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const InstantCode = `{
  "website": [
    "https://www.bbc.com/news/election",
    "https://www.cnn.com/elections/results",
    "https://www.nytimes.com/interactive/2024/11/05/us/elections/results.html"
  ],
  "llm_prompt": {
    "description": "Parse the scraped election data and determine the winning candidate.",
    "prompt": "
      You are provided with election results from three different websites (BBC, CNN, and The New York Times). Each website has data about two candidates (Candidate 1 and Candidate 2) and the number of votes they received.
      Your task is to:
      1. Compare the vote counts for Candidate 1 and Candidate 2 across all three websites.
      2. Reconcile any discrepancies in the vote totals between the sources.
      3. Determine the candidate with the majority of total votes across the three sources.
      4. Output the result in the following JSON format:
        {
          'winning_candidate': 'Candidate 1 or 2',
          'total_votes': total_number_of_votes
        }
    "
  },
  "output_schema": {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "title": "Election Results",
    "type": "object",
    "properties": {
      "winning_candidate": {
        "type": "string",
        "description": "The name of the winning candidate."
      },
      "total_votes": {
        "type": "number",
        "description": "The total number of votes the winning candidate received across all sources."
      }
    },
    "required": ["winning_candidate", "total_votes"]
  }
}`;

const FullyCode1 = `def custom_fetching_function():
  # Fetch data from a weather API
  weather_data = fetch_from_api("https://api.weather.com/v3/wx/forecast/daily")
  
  # Process and extract the relevant weather information
  temperature = weather_data['temperature']
  wind_speed = weather_data['windSpeed']
  
  # Return structured data
  return {
    'temperature': temperature,
    'wind_speed': wind_speed
  }`;

  const FullyCode2 = `def custom_aggregation_function(data_sources):
  # Extract prices from multiple sources
  prices = [source['price'] for source in data_sources]
  
  # Calculate the median price
  median_price = sorted(prices)[len(prices) // 2]
  
  # Return the aggregated result
  return {
    'median_price': median_price
  }`;
  return (
    <ServicesBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Title1>Features</Title1>
        <MainBox>
          <ItemBox>
            <img className="icon-img" src={Icon1Img} alt="icon" />
            <div className="item-main-box">
              <div className="title">Agentic Data Processing</div>
              <div className="desc">Leverage Agents & LLM to extract and process unstructured data, transforming it into actionable insights that are ready for on-chain execution. Check out the use cases.</div>
              <img src={downToMd ? BannerMImg : BannerImg} alt="banner_img" />
            </div>
          </ItemBox>
          <ItemBox isreverse={true}>
            <div className="item-main-box">
              <div className="title">Instant Configuration and Deployment</div>
              <div className="desc">Easily configure new data feeds and deploy them instantly. With IntelliX, all that’s required is a simple configuration to get your data feeds up and running.</div>
              <CodeBlock code={InstantCode} />
            </div>
            <img className="icon-img" src={Icon2Img} alt="icon" />
          </ItemBox>
          <ItemBox>
            <img className="icon-img" src={Icon3Img} alt="icon" />
            <div className="item-main-box">
              <div className="title">Fully Programmable</div>
              <div className="desc desc2">Users have the flexibility to define their own fetching and aggregation functions, having complete control over how data is sourced, validated, and published on-chain</div>
              <CodeBlock code={FullyCode1} />
              <CodeBlock code={FullyCode2} />
            </div>
          </ItemBox>
        </MainBox>
      </Containter>
    </ServicesBox>
  );
};

export default Features;
