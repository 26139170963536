import { styled } from "@mui/material/styles";
import { Stack, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import Icon1Img from "assets/images/usecases/Icon1.png";
import Icon2Img from "assets/images/usecases/Icon2.png";
import Icon3Img from "assets/images/usecases/Icon3.png";
import Icon4Img from "assets/images/usecases/Icon4.png";
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useState } from 'react'
const ServicesBox = styled(Stack)`
  width: 100%;
  background-color: #272727;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 80px 0 75px 0;
  position: relative;
  .swiper-pagination-bullet-active{
    background-color: #00D1AB !important;
  }
  .swiper-pagination-bullet{
    background: #D9D9D9;
    opacity: 1;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 30px 20px",
    },
  })};
`;

const Title1 = styled(Typography)`
  color: #fff;
  font-family: Arboria-Bold;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: '36px',
    },
  })};
`;

const MobileMainBox = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 28px;
  padding: 50px 0 10px;
`;

const MainBox = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 28px;
  padding: 50px 0 80px;
`;

const ItemIconBox = styled("div")<{ismaxheight?: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #999;
  gap: 30px;
  padding: 10px 23px 26px;
  height: ${({ismaxheight}) => ismaxheight ? '95%' : 'auto'};
  img{
    width: 240px;
    vertical-align: middle;
  }
  .title{
    color: #FFF;
    text-align: center;
    font-family: Arboria-Medium;
    font-size: 20px;
    line-height: 25px;
    min-height: 50px;
    display: flex;
    align-items: center;
  }
  .desc{
    color: #999;
    text-align: center;
    font-family: Arboria-Book;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      gap: '10px',
      'img': {
        width: '200px',
      },
      '.desc': {
        fontSize: '14px',
        lineHeight: '20px',
      }
    },
  })};
`;

const UseCases = () => {
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const list = [
    { img: Icon1Img, title: 'Prediction Markets', desc: 'IntelliX automates the retrieval and verification of real-world event outcomes for decentralized prediction markets. It scrapes data from multiple sources such as news sites, official reports, and social media platforms, using an LLM to understand and process the content. IntelliX then employs a secure and tamper-proof aggregation algorithm to ensure that the published result is accurate and trustworthy, enabling trustless, automatic outcome settlement without intermediaries.' },
    { img: Icon2Img, title: 'Insurance Claims Verification', desc: 'IntelliX automates the verification of real-world events that trigger insurance claims, such as weather incidents, natural disasters, and travel disruptions. It scrapes data from multiple sources like weather agencies, government reports, and news outlets, processing the information using an LLM. The result is then published on-chain, enabling smart contracts to execute and trigger automatic payouts to policyholders.' },
    { img: Icon3Img, title: 'Regulatory Compliance for Tokenized Assets', desc: 'IntelliX ensures tokenized assets comply with local regulations by parsing legal documents and regulatory updates. It processes this information using an LLM and publishes the compliance status on-chain, allowing decentralized platforms to automatically enforce trading restrictions and verify transactions without manual oversight.' },
    { img: Icon4Img, title: 'Real Estate Tokenization', desc: 'IntelliX connects real-world property data to blockchain platforms by scraping and processing market reports, property listings, and real estate data using an LLM. It publishes structured information, such as property valuations and rental trends, on-chain, enabling decentralized real estate platforms to automatically update tokenized asset prices and manage contracts seamlessly.' }
  ]
  return (
    <ServicesBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Title1>Use Cases</Title1>
        { downToMd ? (
          <MobileMainBox>
            <Swiper
              onSwiper={setSwiperRef}
              style={{ width: '100%', paddingBottom: '68px' }}
              initialSlide={0}
              pagination={true}
              spaceBetween={20}
              modules={[Pagination]}
            >
              {list.map((item: any, index: number) =>
                <SwiperSlide key={index}>
                  <ItemIconBox key={index} ismaxheight={true}>
                    <img src={item.img} alt="icon" />
                    <div className="title">{ item.title }</div>
                    <div className="desc">{ item.desc }</div>
                  </ItemIconBox>
                </SwiperSlide>
              )
              }
            </Swiper>
          </MobileMainBox>
        ) : (
          <MainBox>
            { list.map((item: any, index: number) => {
              return (
                <ItemIconBox key={index}>
                  <img src={item.img} alt="icon" />
                  <div className="title">{ item.title }</div>
                  <div className="desc">{ item.desc }</div>
                </ItemIconBox>
              )
            }) }
          </MainBox>
        ) }
      </Containter>
    </ServicesBox>
  );
};

export default UseCases;
