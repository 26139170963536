import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Logo: React.FC<IconProps> = ({ width = "124px", height = "34px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg width="124" height="34" viewBox="0 0 124 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5587 0H8.44134C3.77932 0 0 3.77932 0 8.44134V25.5587C0 30.2207 3.77932 34 8.44134 34H25.5587C30.2207 34 34 30.2207 34 25.5587V8.44134C34 3.77932 30.2207 0 25.5587 0Z" fill="url(#paint0_linear_1171_690)" />
        <path d="M40.4941 9.13892H45.2571V26.4909H40.4941V9.13892Z" fill="white" />
        <path d="M48.4473 14.5361H52.2842V16.9588C52.7791 16.1444 53.4249 15.4319 54.1869 14.8595C54.9632 14.3449 55.8818 14.0875 56.8125 14.1238H57.3326H57.4848C57.5332 14.1304 57.5822 14.1304 57.6306 14.1238C58.235 14.1935 58.8193 14.3833 59.3493 14.6819C59.8893 14.9819 60.3401 15.4195 60.6558 15.9504C60.9072 16.3967 61.0688 16.888 61.1315 17.3964C61.1949 17.9037 61.2329 18.4175 61.252 18.9439V26.4909H57.0535V20.0981C57.0535 19.9522 57.0535 19.8064 57.0535 19.6605C57.0535 19.5147 57.0535 19.3624 57.0535 19.1975C57.0014 18.8705 56.9099 18.5511 56.7808 18.2462C56.6574 17.9435 56.4334 17.6926 56.1466 17.5359C56.0596 17.4939 55.9681 17.462 55.8739 17.4408C55.7657 17.4157 55.6551 17.4029 55.5441 17.4027C55.4725 17.3935 55.4 17.3935 55.3284 17.4027H54.9606C54.8974 17.3946 54.8335 17.3946 54.7703 17.4027C54.6922 17.3966 54.6138 17.3966 54.5357 17.4027C54.4667 17.4068 54.3985 17.4197 54.3327 17.4408C54.0423 17.5209 53.7739 17.6658 53.5476 17.8646C53.3213 18.0634 53.143 18.311 53.0263 18.5887C52.8066 19.1348 52.678 19.7133 52.6457 20.301C52.6457 20.504 52.614 20.6943 52.614 20.8845V26.4846H48.479L48.4473 14.5361Z" fill="white" />
        <path d="M63.8393 17.4788H62.0635V14.536H63.8393V10.9401H67.9807V14.536H69.8008V17.4788H67.9807V26.4909H63.8393V17.4788Z" fill="white" />
        <path d="M74.5646 21.3158C74.5866 22.0442 74.8226 22.75 75.2432 23.3452C75.478 23.6271 75.7747 23.851 76.1102 23.9995C76.4457 24.148 76.811 24.2171 77.1775 24.2014H77.26H77.298C77.8159 24.2085 78.3276 24.0888 78.7884 23.8526C79.2497 23.5997 79.63 23.2215 79.8856 22.7617L83.0567 24.0301C82.6006 24.9942 81.8126 25.762 80.8369 26.1928C79.8415 26.6511 78.7616 26.8973 77.6659 26.9158H77.3424C76.3223 26.8899 75.3159 26.6749 74.3743 26.2816C73.4752 25.9468 72.6778 25.3854 72.0595 24.6517C71.6174 24.1352 71.2618 23.5504 71.0067 22.9202C70.7477 22.2928 70.5976 21.6258 70.5627 20.9479V20.7323C70.5572 20.669 70.5572 20.6053 70.5627 20.542C70.5698 20.4692 70.5825 20.3971 70.6008 20.3264C70.6008 20.2756 70.6008 20.2059 70.6008 20.1361C70.6067 20.0729 70.6067 20.0091 70.6008 19.9458C70.6652 19.1225 70.897 18.321 71.282 17.5904C71.6669 16.8598 72.1969 16.2154 72.8395 15.6967C74.0729 14.6807 75.6241 14.1307 77.2219 14.1428C77.5634 14.1437 77.9045 14.167 78.243 14.2126C78.5872 14.2594 78.9282 14.3272 79.2641 14.4155C80.9701 14.9102 82.1117 15.7917 82.6952 17.0729C83.2909 18.4156 83.606 19.8658 83.6211 21.3347L74.5646 21.3158ZM75.4779 17.3139C75.0809 17.7803 74.8121 18.3421 74.6978 18.9438H79.5178C79.4478 18.346 79.1851 17.7872 78.7694 17.3519C78.5545 17.1362 78.2982 16.9659 78.016 16.8515C77.7337 16.7371 77.4313 16.6808 77.1268 16.686C76.8227 16.6634 76.5174 16.7079 76.2325 16.8164C75.9475 16.9249 75.6899 17.0947 75.4779 17.3139Z" fill="white" />
        <path d="M85.5674 8.13696H89.7849V26.491H85.5674V8.13696Z" fill="white" />
        <path d="M92.3789 8.13696H96.6027V26.491H92.3789V8.13696Z" fill="white" />
        <path d="M99.8244 9.15797C100.223 8.69784 100.785 8.40933 101.391 8.35251H101.467C101.774 8.35727 102.077 8.42765 102.355 8.55891C102.633 8.69016 102.879 8.8793 103.078 9.11361C103.528 9.57256 103.781 10.1894 103.782 10.8323C103.799 11.1339 103.747 11.4353 103.629 11.7135C103.512 11.9917 103.332 12.2392 103.103 12.4369C102.678 12.8082 102.144 13.0309 101.581 13.0711H101.308C100.744 13.0308 100.207 12.8083 99.78 12.4369C99.5527 12.2383 99.3735 11.9907 99.2559 11.7127C99.1384 11.4347 99.0855 11.1337 99.1014 10.8323C99.1037 10.519 99.1689 10.2094 99.2931 9.92178C99.4173 9.63417 99.5979 9.37445 99.8244 9.15797ZM99.3487 14.5361H103.592V26.4909H99.3551L99.3487 14.5361Z" fill="white" />
        <path d="M114.627 13.8638C114.714 14.0914 114.786 14.3244 114.843 14.5614C114.843 14.5044 114.881 14.46 114.894 14.4092L114.951 14.2887C115.02 14.1048 115.103 13.9209 115.185 13.7306C115.266 13.5377 115.362 13.3512 115.471 13.1725L117.538 9.13892H123.151L117.373 17.4788L123.773 26.4909H118.439L115.471 21.3158C115.376 21.1728 115.293 21.0221 115.223 20.8655C115.151 20.7132 115.088 20.5547 115.033 20.3898L114.989 20.2947C114.979 20.2618 114.967 20.2299 114.951 20.1996L114.894 20.079C114.894 20.0283 114.862 19.9839 114.843 19.9268L114.786 20.1551C114.769 20.2367 114.741 20.3156 114.703 20.3898C114.653 20.5533 114.589 20.7124 114.513 20.8655C114.443 21.0208 114.362 21.1713 114.272 21.3158L111.329 26.4909H105.989L112.388 17.3963L106.592 9.15161H112.198L114.272 13.1852C114.407 13.4022 114.526 13.6291 114.627 13.8638Z" fill="white" />
        <path d="M24.8229 17.4979C25.0983 17.406 25.3377 17.2297 25.5074 16.9941C25.677 16.7585 25.7681 16.4754 25.7678 16.1851V12.8302C25.767 12.6107 25.7141 12.3945 25.6136 12.1993C25.5131 12.0042 25.3678 11.8358 25.1895 11.7077C25.0113 11.5796 24.8053 11.4956 24.5883 11.4625C24.3713 11.4294 24.1496 11.4481 23.9413 11.5173L17.3582 13.7561H17.2567L9.15786 16.4832C8.88246 16.5751 8.64297 16.7513 8.47336 16.987C8.30374 17.2226 8.21261 17.5057 8.21289 17.796V21.151C8.21274 21.371 8.26485 21.5879 8.36495 21.7838C8.46504 21.9797 8.61025 22.1491 8.78861 22.2779C8.96697 22.4067 9.17338 22.4914 9.39085 22.5248C9.60831 22.5582 9.8306 22.5394 10.0394 22.4701L16.7494 20.2124C16.9206 20.2124 17.2314 20.3835 17.2377 21.8042C17.2347 21.8422 17.2347 21.8804 17.2377 21.9184V25.3177C17.2375 25.6193 17.3359 25.9127 17.518 26.1531C17.7001 26.3936 17.9558 26.5679 18.2461 26.6495L24.0237 28.2922C24.2298 28.3512 24.4467 28.3615 24.6573 28.3222C24.868 28.283 25.0666 28.1954 25.2376 28.0662C25.4086 27.9371 25.5472 27.7699 25.6425 27.5779C25.7379 27.386 25.7873 27.1746 25.7869 26.9603V23.5609C25.787 23.2593 25.6886 22.966 25.5065 22.7255C25.3245 22.4851 25.0688 22.3107 24.7785 22.2291L19.8506 20.8275C19.4257 20.7007 18.0622 20.2377 18.5822 19.6098L24.8229 17.4979Z" fill="#081909" />
        <path d="M16.7557 12.1451C16.7557 12.1451 16.7557 12.1007 16.7557 12.0753V8.68233C16.7559 8.38073 16.6575 8.08736 16.4754 7.84691C16.2934 7.60646 16.0377 7.43213 15.7473 7.35051L9.97601 5.70792C9.77001 5.64895 9.55311 5.63859 9.34245 5.67781C9.13178 5.71703 8.93312 5.80467 8.76213 5.93383C8.59115 6.06299 8.45253 6.23019 8.35721 6.42211C8.2619 6.61402 8.2125 6.82547 8.21291 7.03975V10.4391C8.21128 10.7401 8.3084 11.0334 8.4894 11.274C8.67039 11.5145 8.92526 11.6891 9.21496 11.7709L14.2886 13.2106C14.815 13.3755 15.9312 13.8004 15.4746 14.3712L17.2758 13.737C17.0792 13.756 16.7557 13.6228 16.7557 12.1451Z" fill="#081909" />
        <defs>
          <linearGradient id="paint0_linear_1171_690" x1="17" y1="0" x2="17" y2="34" gradientUnits="userSpaceOnUse">
            <stop stop-color="#34FCD8" />
            <stop offset="1" stop-color="#2DF3B7" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Logo;
